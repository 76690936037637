.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>td:first-child {
    box-shadow: #306FFF 4px 0px 0px 0px inset;
}

canvas#responsive-canvas {
    width: 100%;
    height: 100%;

    @media (max-width: 425px) {
        min-height: 100px;
    }
}

.label__medium:not(.dx-texteditor-empty),
.label__medium.dx-state-focused {
    @media (max-width: 924px) {
        height: 60px;

        .dx-texteditor-container>.dx-texteditor-input-container>.dx-texteditor-input {
            padding-top: 36px;
        }

        .dx-texteditor-label>.dx-label>span {
            white-space: normal;
            height: 36px !important;
        }
    }
}

.label__long:not(.dx-texteditor-empty),
.label__long.dx-state-focused {
    @media (max-width: 924px) {
        height: 60px;

        .dx-texteditor-container>.dx-texteditor-input-container>.dx-texteditor-input {
            padding-top: 36px;
        }

        .dx-texteditor-label>.dx-label>span {
            white-space: normal;
            height: 36px !important;
        }
    }

    @media (max-width: 450px) {
        height: 84px;

        .dx-texteditor-container>.dx-texteditor-input-container>.dx-texteditor-input {
            padding-top: 60px;
        }

        .dx-texteditor-container>.dx-texteditor-input-container>textarea.dx-texteditor-input {
            padding-top: 26px;
        }

        .dx-texteditor-label>.dx-label>span {
            height: 60px !important;
        }
    }
}