:root {
    --white-color: #ffffff;
    --black-color: #000000;
    --red-text-color: #D32F2F;
    --red-status-color: #F03939;
    --grey-text-color: #8D8E9B;
    --grey-status-color: #D1D2D7;
    --grey-icon-color: #CCCCCC;
    --green-text-color: #3CA761;
    --green-status-color: #4DDC7E;
    --dark-green-color: #24683B;
    --yellow-text-color: #F3B106;
    --yellow-status-color: #F8CB58;
    --light-red-color: #FFCED0;
    --light-pink-color: #FAECED;
    --light-green-color: #C5FEDC;
    --light-yellow-color: #FFF7AC;
    --light-grey-color: #E0E0E0;
    --light-blue-color: #306FFF;
    --grey-blue-color: #34689E;
    --dark-blue-color: #002875;
    --black-blue-color: #000932;

    --border-color: #9E9E9E;
    --hover-color: #F5F5F5;
    --active-color: #306FFF1A;
    --selected-color: #F8F9FD;
    --background-color: #F7F7F7;
    --background-grey-color: #FAFAFA;
    --background-green-color: #F0FAF9;
    --background-red-color: #FAECED;
    --background-blue-color: #EAF0FF;
}

@font-face {
    font-family: 'Butler';
    src: url("./fonts/Butler_Bold.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url("./fonts/Montserrat-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url("./fonts/Montserrat-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: Montserrat, 'Courier New', monospace;
}